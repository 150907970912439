/* ************************************************************
:: Template Name: AppMax - App Landing Page Template
:: Template URI: http://event-theme.com/
:: Template Author Name: plexdesign
:: Template Author URI: https://themeforest.net/user/plexdesigns
:: Version: 1.0.0
:: Created: 20 Jun 2020
************************************************************ 
*
*
******* :: INDEX OF CSS :: *******
:: 1.0 WEB FONTS
:: 2.0 IMPORT ALL CSS
:: 3.0 GLOBAL CSS
:: 4.0 COMMON CSS
:: 5.0 PRELOADER CSS
:: 6.0 SCROLL TO TOP AREA CSS
:: 7.0 HEADER AREA CSS
:: 8.0 WELCOME AREA CSS
    :: 8.1 WELCOME AREA HOMEPAGE-2 CSS
    :: 8.2 WELCOME AREA HOMEPAGE-3 CSS
    :: 8.3 WELCOME AREA HOMEPAGE-4 CSS
    :: 8.4 WELCOME AREA HOMEPAGE-6 CSS
    :: 8.5 WELCOME AREA HOMEPAGE-9 CSS
:: 9.0 PROMO AREA CSS
:: 10.0 FEATURES AREA CSS
:: 11.0 SERVICE AREA CSS
:: 12.0 DISCOVER AREA CSS
:: 13.0 WORK AREA CSS
:: 14.0 SCREENSHOTS AREA CSS
:: 15.0 REVIEWS AREA CSS
:: 16.0 PRICE PLAN AREA CSS
:: 17.0 FAQ AREA CSS
:: 18.0 TEAM AREA CSS
:: 19.0 SUBSCRIBE AREA CSS
:: 20.0 BLOG AREA CSS
:: 21.0 BREADCRUMB AREA CSS
:: 22.0 BLOG PAGE AREA CSS
:: 23.0 BLOG PAGE DETAILS AREA CSS
:: 24.0 CONTACT AREA CSS
:: 25.0 FOOTER AREA CSS
:: 26.0 ERROR AREA CSS
:: 27.0 COMING SOON AREA CSS
:: 28.0 RTL AREA CSS
:: 29.0 DARK AREA CSS
:: 30.0 PREVIEW AREA CSS
****************************** */

/* ******************************
:: 1.0 WEB FONTS
****************************** */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500;700;900&family=Poppins:wght@300;400;500&display=swap');

/* ******************************
:: 2.0 IMPORT ALL CSS
****************************** */
@import url(bootstrap.min.css);
/* @import url(all.min.css); */
/* @import url(../font/flaticon.css); */
/* @import url(themify-icons.css); */
/* @import url(pe-icon-7-stroke.css); */
/* @import url(animate.min.css); */
@import url(aos.css);
@import url(owl.carousel.min.css);
/* @import url(jquery.fancybox.min.css); */

/* ******************************
:: 3.0 GLOBAL CSS
****************************** */
* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #222;
}

.main {
    background: #fff;
}

section,
.section {
    position: relative;
}

.container {
    width: 100%;
    margin: 0 auto;
}

h1,
.h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Noto Sans TC', sans-serif;
    font-weight: 700;
    color: #222;
    margin-bottom: 0;
}

h1,
.h1 {
    font-size: 3.5em;
    line-height: 1.2;
}

h2 {
    font-size: 45px;
    line-height: 1.3;
}

h3 {
    font-size: 20px;
    line-height: 1.2;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
    font-weight: 500;
}

p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #333;
    margin-bottom: 0;
}

a {
    color: #444;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

a,
a:hover,
a:focus,
.btn:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.text-body > a {
    text-decoration: underline;
    color: #212529;
}

ol,
ul {
    margin: 0;
    padding: 0;
}

ol li,
ul li {
    list-style: none;
}

img {
    height: auto;
    max-width: 100%;
}

/* ******************************
:: 4.0 COMMON CSS
****************************** */
.fw-3 {
    font-weight: 300;
}

.fw-4 {
    font-weight: 400;
}

.fw-5 {
    font-weight: 500;
}

.fw-6 {
    font-weight: 600;
}

.fw-7 {
    font-weight: 700;
}

.op-5 {
    opacity: 0.5;
}

.op-7 {
    opacity: 0.7;
}

.avatar-sm {
    height: 3rem;
    width: 3rem;
}

.avatar-md {
    height: 4rem;
    width: 4rem;
}

.avatar-lg {
    height: 5rem;
    width: 5rem;
}

.price-lg {
    max-width: 6rem;
}

.text-underlined,
.text-underlined:hover,
.text-underlined:focus {
    text-decoration: underline;
}

.text-secondary {
    color: #666 !important;
}

.text-muted {
    color: #888 !important;
}

.color-primary {
    color: #222 !important;
}

.featured-icon > span::before {
    font-size: 4.25rem;
    line-height: 1;
    color: #777;
    margin: 0;
}

.radius-100 {
    border-radius: 100px !important;
}

.h-100vh {
    height: 100vh;
}

.inner .h-100vh {
    height: 100vh !important;
}

.single-brand {
    min-height: 100px;
}

.owl-carousel .owl-item img {
    width: auto;
}

.owl-prev:focus,
.owl-next:focus {
    outline: none;
}

/*SECTION HEADING*/
.section-heading {
    margin-bottom: 80px;
}

.section-heading > span,
.section-span {
    font-size: 14px;
    font-family: 'Noto Sans TC',sans-serif;
    font-weight: 700;
    color: #666;
    text-transform: uppercase;
    display: block;
    letter-spacing: .5px;
    margin-bottom: 20px;
}

.section-heading > h2 {
    position: relative;
}

.section-heading > h2::after {
    position: absolute;
    content: '';
    height: 2px;
    width: 70px;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.homepage-5 .features-area .section-heading > h2::after,
.contact-page .contact-area .section-heading > h2::after {
    left: 0;
    transform: inherit;
}

/*SECTION PADDING*/
.pt_0 {
    padding-top: 0;
}

.ptb_50 {
    padding: 50px 0;
}

.ptb_100 {
    padding: 100px 0;
}

.ptb_150 {
    padding: 150px 0;
}

.ptb_180 {
    padding: 180px 0;
}

/*BUTTONS*/
.btn {
    font-size: .825rem;
    font-weight: 700;
    font-family: 'Noto Sans TC',sans-serif;
    background-color: transparent;
    color: #fff;
    line-height: 1;
    letter-spacing: .25px;
    text-transform: uppercase;
    padding: .915rem 1.85rem;
    border: 2px solid transparent;
    border-radius: 100px;
    transition: all 450ms ease-in-out;
}

.btn.appmax-btn {
    background-color: transparent;
    color: #333;
    border: 2px solid #ccc;
}

.btn-purple {
    color: #fff;
}

.btn:hover,
.btn:focus {
    background-color: #333;
    color: #fff !important;
    border-color: #333;
}

.btn-success {
    background: #28a745 !important;
    border-color: #28a745 !important;
}

.button-group {
    margin-top: 30px;
}

.button-group a {
    margin-right: 10px;
}

.button-group a:last-child {
    margin-right: 0;
}

.store-buttons a {
    text-align: left;
}

.store-buttons img {
    max-width: 190px;
}

.store-buttons.store-black img {
    max-width: 170px;
    margin-bottom: 10px;
}

.store-buttons .store-btn-content > span:first-child {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
}

/*BG SHAPE*/
.shapes-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.shape {
    position: relative;
}

.bg-shape {
    position: absolute;
    height: 190%;
    width: 100%;
    display: block;
    border-radius: 120px;
    background: linear-gradient(-47deg, #8731E8 0%, #4528DC 100%);
    bottom: 0;
    right: 0;
    -webkit-transform: translate(35%,-28%) rotate(-35deg);
    transform: translate(35%,-28%) rotate(-35deg);
    z-index: 0;
}

@media (max-width: 991px) {
    .bg-shape {
        height: 130%;
    }
}

/*SHAPE DIVIDER*/
.shape-bottom {
    position: absolute;
    top: auto;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
}

.homepage-5 .shape-bottom svg {
    overflow: hidden;
    width: 100%;
    line-height: 0;
    direction: ltr;
    transform: rotate(180deg);
}

/*BACKGROUND OVERLAY*/
.bg-overlay,
.overlay-white,
.overlay-dark {
    position: relative;
    z-index: 0;
}

.bg-overlay::after,
.overlay-white:after,
.overlay-dark:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.bg-overlay::after {
    opacity: 0.94;
    z-index: -1;
}

.overlay-white:after {
    background-color: #ffffff;
    opacity: 0.89;
    z-index: -1;
}

.overlay-dark::after {
    background-color: #2e2f33;
    opacity: 0.94;
    z-index: -1;
}

/*BACKGROUND COLORS*/
.bg-gray {
    background-color: #f6f9fe;
}

.bg-inherit {
    background: inherit !important;
}

.bg-overlay::after,
.benifits-item:after {
    background-image: linear-gradient(to right, rgba(32, 40, 119, 0.97), rgba(55, 46, 149, 0.97), rgba(83, 49, 177, 0.96), rgba(114, 48, 205, 0.96), rgba(150, 41, 230, 0.97));
}

/*SOCIAL ICONS*/
.social-icons > a {
    margin: 5px;
    width: 45px;
    height: 45px;
    font-size: 20px;
    color: #fff;
    border-radius: 50%;
    overflow: hidden;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.social-icons svg {
    display: block;
    height: 100%;
    line-height: 45px;
    margin: 0 auto;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.social-icons > a:hover svg:first-child {
    margin-top: -45px;
}

.social-icons > a.facebook {
    background-color: #3b5999 !important;
}

.social-icons > a.xing {
    background-color: #126567 !important;
}

.social-icons > a.linkedin {
    background-color: #0e76a8 !important;
}

.social-icons > a.vine {
    background-color: #00b489 !important;
}

/*BACKGROUND IMAGES*/
.welcome-area,
.inner-area {
    background: rgba(0, 0, 0, 0) url("../img/bg/welcome-bg.jpg") no-repeat scroll center center / cover;
}

.homepage-3 .welcome-area {
    background: rgba(0, 0, 0, 0) url("../img/bg/welcome-bg-3.jpg") no-repeat scroll center center / cover;
}

.homepage-4 .welcome-area {
    background: rgba(0, 0, 0, 0) url("../img/blog/blog-7.jpg") no-repeat scroll center center / cover;
}

.homepage-5 .welcome-area {
    background: rgba(0, 0, 0, 0) url("../img/blog/blog-5.jpg") no-repeat scroll center center / cover;
}

.homepage-6 .welcome-area {
    background: rgba(0, 0, 0, 0) url("../img/bg/welcome-bg-2.jpg") no-repeat scroll center center / cover;
}

.accounts .welcome-area {
    background: rgba(0, 0, 0, 0) url("../img/bg/accounts.jpg") no-repeat scroll center top / cover;
}

.breadcrumb-area {
    background: rgba(0, 0, 0, 0) url("../img/bg/welcome-bg.jpg") no-repeat scroll top center / cover;
}

.blog .breadcrumb-area {
    background: rgba(0, 0, 0, 0) url("../img/blog/blog-5.jpg") no-repeat scroll center center / cover;
}

.inner .welcome-area {
    background: rgba(0, 0, 0, 0) url("../img/bg/welcome-bg.jpg") no-repeat scroll center center / cover;
}

.preview .welcome-area {
    background: rgba(0, 0, 0, 0) url("../img/blog/blog-9.jpg") no-repeat scroll center center / cover;
}

/* ******************************
:: 5.0 PRELOADER AREA CSS
****************************** */
#loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99999999999;
}

.spinner {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    -webkit-animation: rotatee 2.0s infinite linear;
    animation: rotatee 2.0s infinite linear;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    background-color: #7c4fe0;
    display: inline-block;
    position: absolute;
    top: 0;
    border-radius: 100%;
    -webkit-animation: bouncee 2.0s infinite ease-in-out;
    animation: bouncee 2.0s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0px;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes rotatee {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes rotatee {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes bouncee {
    0%,
    100% {
        -webkit-transform: scale(0.0)
    }

    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes bouncee {
    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

/* ******************************
:: 6.0 SCROLL TO TOP AREA CSS
****************************** */
#scrollUp {
    position: fixed;
    right: 2%;
    bottom: 3%;
    height: 45px;
    width: 40px;
    border-radius: 4px;
    background-color: rgba(35,35,35,0.65);
    text-align: center;
    cursor: pointer;
    z-index: 500;
    display: none;
    -webkit-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#scrollUp > svg {
    display: block;
    height: 100%;
    font-size: 22px;
    color: #fff;
    margin: 0 auto;
}

#scrollUp:hover {
    background-color: #666;
}

/* ******************************
:: 7.0 HEADER AREA CSS
****************************** */
.navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 999;
    -webkit-transition: .3s;
    transition: .3s;
    background: transparent;
}

.navbar .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    -webkit-transition: .3s;
    transition: .3s;
}

.navbar .navbar-brand-sticky {
    display: none;
}

.navbar-sticky {
    -webkit-transition: none;
    transition: none;
}

.navbar-sticky-transitioned {
    -webkit-transition: .3s;
    transition: .3s;
}

.navbar-sticky-moved-up {
    position: fixed;
    top: 0;
    background: #fff;
    margin-top: -100px;
}

[data-theme=dark] .navbar-sticky-moved-up {
    background: #01081f;
    -webkit-box-shadow: 0 4px 25px rgba(30, 39, 69, 0.3), 0 1px 0px rgba(30, 39, 69, 0.4);
    box-shadow: 0 4px 25px rgba(30, 39, 69, 0.3), 0 1px 0px rgba(30, 39, 69, 0.4);
}

.navbar-sticky-on {
    margin-top: 0;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
}

.navbar-sticky-on .navbar-brand-regular {
    display: none;
}

.navbar-sticky-on .navbar-brand-sticky {
    display: inline;
}

/*NAVBAR DARK*/
.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.navbar-dark .btn-outline {
    color: #fff;
}

.navbar-dark.navbar-sticky-on .navbar-nav .nav-link {
    color: #222;
}

[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link {
    color: #e7eaee;
}

.navbar-dark.navbar-sticky-on .navbar-nav .nav-link:hover,
.navbar-dark.navbar-sticky-on .navbar-nav .nav-link:focus,
.navbar-dark.navbar-sticky-on .navbar-nav .nav-link.active,
.navbar-dark.navbar-sticky-on .navbar-nav .nav-link.current-menu-item {
    color: #2c2e30;
}

[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link:hover,
[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link:focus,
[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link.active,
[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link.current-menu-item {
    color: #D2D5DA;
}

/* ******************************
:: 8.0 WELCOME AREA CSS
****************************** */
.welcome-area {
    height: 850px;
    z-index: 1;
}

@media (min-width: 992px) {
    .download-page .welcome-intro > h1 {
        font-size: 3.8em;
    }

    .download-page .welcome-intro p {
        font-size: 16px;
    }
}

.welcome-area .video-icon {
    position: absolute;
    top: 50%;
    left: 70%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.play-btn {
    position: relative;
    display: inline-block;
    height: 100px;
    width: 100px;
    background-color: #fff;
    border-radius: 50%;
}

.btn-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
}

.play-icon {
    font-size: 22px;
    /*color: #7c4fe0;*/
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@-webkit-keyframes grow {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    to {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes grow {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    to {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0;
    }
}

.play-animation {
    -webkit-animation: grow 3s infinite;
    animation: grow 3s infinite;
}

.animation-short {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.welcome-area.bg-overlay::after {
    z-index: -2;
}

.welcome-area .shape-bottom {
    bottom: -6px;
    z-index: -1;
}

/* ***********************************
:: 8.1 WELCOME AREA HOMEPAGE-2 CSS
**************************************/
.homepage-2 .welcome-area::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background-color: #f6f9fe;
    top: 0;
    left: 0;
    z-index: -2;
}

@media (min-width: 992px) {
    .homepage-2 .welcome-thumb {
        max-width: 400px;
    }
}

/* ***********************************
:: 8.2 WELCOME AREA HOMEPAGE-3 CSS
**************************************/
.homepage-3 .welcome-area .shape-bottom svg {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

/* ***********************************
:: 8.3 WELCOME AREA HOMEPAGE-4 CSS
**************************************/
.homepage-4 .welcome-thumb > img {
    position: relative;
    -webkit-animation: bounceHero 3s infinite;
    animation: bounceHero 3s infinite;
}

@-webkit-keyframes bounceHero {
    0% {
        top: 0px;
    }

    50% {
        top: 25px;
    }

    100% {
        top: 0px;
    }
}

@keyframes bounceHero {
    0% {
        top: 0px;
    }

    50% {
        top: 25px;
    }

    100% {
        top: 0px;
    }
}

/* ***********************************
:: 8.4 WELCOME AREA HOMEPAGE-6 CSS
**************************************/
.homepage-6 .welcome-area .subscribe-form {
    position: relative;
    display: block;
    width: 80%;
}

.homepage-6 .welcome-area .subscribe-form input {
    font-size: 14px;
    border-radius: 10px;
    text-indent: 3%;
    -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
}

.homepage-6 .welcome-area .subscribe-form button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

/* ***********************************
:: 8.5 WELCOME AREA HOMEPAGE-9 CSS
**************************************/
.homepage-9 .welcome-area::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0 0 50% 50%/0 0 50% 50%;
    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
    z-index: -2;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    -webkit-animation: animatedCircles 25s linear infinite;
    animation: animatedCircles 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 12s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 18s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    -webkit-animation-delay: 15s;
    animation-delay: 15s;
    -webkit-animation-duration: 45s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 35s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 11s;
    animation-duration: 11s;
}

@-webkit-keyframes animatedCircles {
    0% {
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        -webkit-transform: translateY(-1000px) rotate(720deg);
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

@keyframes animatedCircles {
    0% {
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        -webkit-transform: translateY(-1000px) rotate(720deg);
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

/* ******************************
:: 9.0 PROMO AREA CSS
****************************** */
.single-promo {
    position: relative;
    transition: all 0.3s ease 0s;
}

.single-promo::after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background: linear-gradient(to right, rgba(32, 40, 119, 0.97), rgba(55, 46, 149, 0.97), rgba(83, 49, 177, 0.96), rgba(114, 48, 205, 0.96), rgba(150, 41, 230, 0.97));
    bottom: 0;
    left: 0;
    opacity: 0;
}

.single-promo:hover,
.homepage-9 .single-promo {
    background-color: #fff;
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}

.single-promo:hover::after,
.homepage-9 .single-promo::after {
    opacity: 1;
}

.single-promo > i {
    font-size: 4.5em;
}
.dark .promo-area .col-12.col-sm-6:nth-child(2n) .single-promo,
.homepage-9 .promo-area .col-12.col-sm-6:nth-child(2n) .single-promo {
    margin-top: 3rem !important;
}

@media (max-width: 575px) {
    .dark .promo-area .col-12.col-sm-6:nth-child(2n) .single-promo,
    .homepage-9 .promo-area .col-12.col-sm-6:nth-child(2n) .single-promo {
        margin-top: .5rem !important;
    }
}

.homepage-3 .promo-area {
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.homepage-3 .single-promo {
    background-color: #fff;
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
}

@media (max-width: 991px) {
    .homepage-3 .promo-area {
        top: inherit;
        transform: inherit;
    }
}

/* ******************************
:: 10.0 FEATURES AREA CSS
****************************** */
.features-slider-wrapper {
    padding: 67px 23px 90px 26px;
    background-size: 100%;
    max-width: 290px;
    margin: 0 auto;
}

/* ******************************
:: 11.0 SERVICE AREA CSS
****************************** */
.service-icon > i {
    font-size: 30px;
}

.service-list.style-two > li {
    list-style: disc;
    margin-left: 1rem;
}

/* ******************************
:: 12.0 DISCOVER AREA CSS
****************************** */
.list-box > span {
    position: relative;
}

.list-box > span::before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    border: 2px solid #565656;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

/* ******************************
:: 13.0 WORK AREA CSS
****************************** */
.work-icon > span {
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 2.5em;
    color: #222;
    position: relative;
    display: inline-block;
    height: 55px;
    width: 55px;
    line-height: 55px;
}

.work-icon > span::before,
.work-icon > span::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    border: 3px solid rgba(34,34,34,0.65);
    border-radius: 50%;
    top: 0;
    left: 0;
}

.work-icon > span::after {
    opacity: 0;
    transition: all 0.3s ease 0s;
}

.single-work:hover .work-icon > span::after {
    transform: translateX(-8px);
    border: 3px solid rgba(34,34,34,0.85);
    opacity: 1;
}

@media (max-width: 767px) {
    .single-work::after {
        display: none;
    }
}

.work-btn > .btn:hover,
.work-btn > .btn:focus {
    background-color: transparent;
    color: #222 !important;
    border-color: #222;
}

.dark .work-btn > .btn:hover,
.dark .work-btn > .btn:focus {
    color: #fff !important;
}

/* ******************************
:: 14.0 SCREENSHOTS AREA CSS
****************************** */
.screenshot-wrapper {
    position: relative;
    z-index: 0;
}

.screenshot-wrapper .screenshot-cover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    overflow: hidden;
}

.single-screenshot > img {
    max-width: 290px;
}

.app-screenshots .owl-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: 50%;
    bottom: -50px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.app-screenshots.owl-carousel button.owl-dot {
    height: 8px;
    width: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    outline: none;
    margin: 5px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

/* ******************************
:: 15.0 REVIEWS AREA CSS
****************************** */

.carousel .carousel-indicators li {  background-color: gray; }
.carousel .carousel-indicators li.active { background-color: var(--primary); }

.carousel-control-prev {
 left: -100px;
}
.carousel-control-next {
 right: -100px;
}

.carousel-control-next-icon {background-image: url("../img/next.png");}
.carousel-control-prev-icon {background-image: url("../img/prev.png");}

.single-review .card-top {
    position: relative;
    padding: 1.5rem 1.8rem;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single-review .reviewer {
    position: relative;
}

.single-review .reviewer::after {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    background-color: #f5f7fb;
    top: calc(100% - 10px);
    left: 15%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.single-review:hover .card-top {
    -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125);
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125);
}

/* ******************************
:: 16.0 PRICE PLAN AREA CSS
****************************** */
.single-price-plan {
    -webkit-box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.09);
    box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.09);
    background-color: #fff;
    border-radius: 1rem;
}

.plan-title > h3 {
    letter-spacing: 3px;
    opacity: 0.8;
}

.plan-price span:not(.h1) {
    font-size: 34px;
}

.plan-price sub {
    top: 0;
    font-size: 24px;
}

@media(max-width: 991px) {
    .plan-price span:not(.h1) {
        font-size: 30px;
    }

    .plan-price sub {
        font-size: 20px;
    }
}

@media(max-width: 575px) {
    .plan-price span:not(.h1) {
        font-size: 26px;
    }
}

.plan-features > li {
    color: #333;
}

.plan-button > .btn {
    display: block;
}

/* ******************************
:: 17.0 FAQ AREA CSS
****************************** */
.card-header .btn {
    position: relative;
    display: block;
    width: 100%;
    background: transparent;
    color: #222;
    text-align: left;
    border: 0;
}

.card-header .btn.collapsed {
    color: #444;
}

.card-header .btn:hover,
.card-header .btn:focus,
.card-header .btn:active {
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    color: inherit !important;
}

.card-header .btn::after,
.card-header .btn.collapsed::after {
    position: absolute;
    top: 50%;
    left: calc(100% - 1.25rem);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 15px;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
}

.card-header .btn::after {
    content: '\f068';
}

.card-header .btn.collapsed::after {
    content: '\f067';
}

/* ******************************
:: 18.0 TEAM AREA CSS
****************************** */
.single-team {
    background-color: #fff;
    -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease 0s;
}

.team-photo {
    position: relative;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 3px solid #fff;
    -webkit-box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15);
}

.team-photo:before,
.team-photo:after {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    transition: all 0.2s ease 0s;
}

.team-photo:before {
    content: "";
    top: 0;
    width: 114px;
    height: 114px;
    opacity: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
    -webkit-transform: scale(.8);
    transform: scale(.8);
}

.team-photo:after {
    content: "";
    top: 5px;
    width: 104px;
    height: 104px;
    background-color: rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
}

.team-photo img {
    height: 104px;
    width: 104px;
    border-radius: 50%;
    margin: 5px;
}

.team-name {
    font-size: 16px;
    transition: color 0.3s ease 0s;
}

.team-post {
    font-size: 13px;
    font-weight: 400;
    color: #555;
    text-transform: uppercase;
}

.team-media > a {
    font-size: 16px;
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
}

.single-team:hover {
    -webkit-box-shadow: 0 1px 8px -2px hsla(0, 0%, 0%, 0.15);
    box-shadow: 0 1px 8px -2px hsla(0, 0%, 0%, 0.15);
}

.single-team:hover .team-photo:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.single-team:hover .team-photo:after {
    height: 124px;
    width: 124px;
    top: -5px;
    left: -5px;
    background-color: transparent;
    -webkit-box-shadow: 0 5px 10px rgba(124, 79, 224, 0.2);
    box-shadow: 0 5px 10px rgba(124, 79, 224, 0.2);
}

/* ******************************
:: 19.0 SUBSCRIBE AREA CSS
****************************** */
.form-control {
    font-size: 15px;
    height: 60px;
    border: none;
    outline: none;
    padding-left: 20px;
    border-radius: 8px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.form-control:focus {
    border: none;
    outline: none;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.subscribe-form .form-group input {
    display: block;
    text-align: center;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.subscribe-form .form-group input::-webkit-input-placeholder {
    text-align: center;
}

.subscribe-form .form-group input:-ms-input-placeholder {
    text-align: center;
}

.subscribe-form .form-group input::placeholder {
    text-align: center;
}

.subscribe-form button {
    height: 60px;
    border-radius: 8px;
}

/* ******************************
:: 20.0 BLOG AREA CSS
****************************** */
.blog-area {
    z-index: 1;
}

.single-blog {
    position: relative;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    transition: all 0.3s ease 0s;
}

.blog-thumb img {
    display: block;
    width: 100%;
    border-radius: 6px;
}

.single-blog .blog-date,
.single-blog-details .blog-date {
    position: absolute;
    top: 3%;
    right: 4%;
}

.single-blog .blog-date > span,
.single-blog-details .blog-date > span {
    font-size: 14px;
    padding: .8rem;
    background-color: rgba(255,255,255,0.84);
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.single-blog-details .blog-date {
    top: 1%;
    right: 5%;
}

.blog-content > p {
    line-height: 1.8;
}

.single-blog .btn {
    font-size: 13px;
    font-weight: 500;
    padding: 0.7rem 0.9rem;
}

.single-blog:hover {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}

/* ******************************
:: 21.0 BREADCRUMB AREA CSS
****************************** */
.breadcrumb-area {
    height: 450px;
    z-index: 1;
}

.breadcrumb-content > h3 {
    font-size: 38px;
}

.breadcrumb {
    background-color: transparent;
    margin: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    color: #fff;
}

.blog .breadcrumb-item > a,
.blog .breadcrumb-item,
.blog .breadcrumb-item::before,
.blog .breadcrumb-item.active {
    color: #fff;
}

/* ******************************
:: 22.0 BLOG PAGE AREA CSS
****************************** */
.blog .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.blog .single-blog {
    margin-bottom: 45px;
}

.pagination > li > a {
    font-size: 14px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    display: inline-block;
    border: 1px solid #444;
    color: #444;
    text-align: center;
}

.pagination li:first-child a,
.pagination li:last-child a {
    display: block;
    border: none;
    position: relative;
}

.pagination li:first-child:hover a,
.pagination li:last-child:hover a {
    background: none;
    color: inherit;
    border: none;
}

.pagination li:first-child:hover a {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
}

.pagination li:last-child:hover a {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
}

/* **********************************
:: 23.0 BLOG PAGE DETAILS AREA CSS
*************************************/
/*Single Widget*/
.single-widget {
    margin-bottom: 35px;
}

.sidebar .single-widget:last-child {
    margin-bottom: 0;
}

.widget-content {
    position: relative;
}

/*Search Widget*/
.search-widget input {
    background-color: #f7f7f7;
    border: 1px solid #eee;
    color: #444;
    height: 45px;
    padding: 10px 15px;
    width: 100%;
    -webkit-transition: -webkit-box-shadow 1s ease 0s;
    transition: -webkit-box-shadow 1s ease 0s;
    transition: box-shadow 1s ease 0s;
    transition: box-shadow 1s ease 0s, -webkit-box-shadow 1s ease 0s;
}

.search-widget input:focus {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

/*Catagory Widget*/
.widget .single-accordion {
    border: 1px solid #eee;
    border-radius: 4px;
}

.widget .single-accordion h5 a {
    color: #444;
    border-bottom: 1px solid #eee;
}

.widget-items li a {
    position: relative;
    border-bottom: 1px solid #eee;
}

.widget-items li a::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    -webkit-transition: all 0.1s ease 0s;
    transition: all 0.1s ease 0s;
}

.widget-items li a:hover,
.widget-items li a:focus,
.widget-items li a.active {
    background-color: #f7f7f7;
}

.widget-items li a:hover::before,
.widget-items li a:focus::before,
.widget-items li a.active::before {
    width: 2px;
    /*background-color: #7c4fe0;*/
}

.widget-items li a span {
    line-height: 1.4;
}

/*Post Widget*/
.post-thumb img {
    border: 1px solid #eee;
}

.post-widget .widget-items .post-date {
    font-size: 13px;
    line-height: 1;
}

.post-content h6 {
    font-weight: 400;
    line-height: 20px;
}

/*Tags Widget*/
.tags-widget .single-accordion {
    border: none;
}

.tags-widget-items a {
    border: 1px solid #e5e5e5;
    font-size: 12px;
}

/*Blog Details*/
.meta-info > ul > li {
    position: relative;
}

.meta-info > ul > li::after {
    position: absolute;
    content: '';
    height: 30%;
    width: 2px;
    background-color: #777;
    top: 50%;
    left: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.meta-info > ul > li:last-child::after {
    display: none;
}

.appmax-blog .blog-share a {
    padding: 0 10px;
}

.blog-share .social-icons > a {
    width: 35px;
    height: 35px;
    font-size: 16px;
}

.blog-share .social-icons > a.facebook:hover {
    background-color: #3b5999 !important;
    color: #fff;
}

.blog-share .social-icons > a.twitter:hover {
    background-color: #55acee !important;
    color: #fff;
}

.blog-share .social-icons > a.google-plus:hover {
    background-color: #dd4b39 !important;
    color: #fff;
}

.blog-share .social-icons svg {
    line-height: 35px;
}

.blog-share .social-icons > a:hover svg:first-child {
    margin-top: -35px;
}

.appmax-blog .blog-details .blog-title > a {
    font-size: 24px;
}

.blockquote {
    background-color: #f7f7f7;
    position: relative;
}

.blockquote::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 2px;
    top: 0;
    left: 0;
}

.blog-comments {
    margin-top: 20px;
}

.admin-thumb img,
.comments-thumb img {
    border: 1px solid #eee;
}

.comments,
.blog-contact {
    margin-right: 200px;
}

.single-comments {
    border: 1px solid #eee;
    margin-bottom: 1rem;
}

.single-comments:last-of-type {
    margin-bottom: 0;
}

.comments-content > h5 > a:last-child {
    font-size: 14px;
    font-weight: 500;
}

.contact-box.comment-box {
    text-align: left;
}

.contact-box.comment-box .form-group input {
    font-size: 15px;
    border: 1px solid #e5e5e5;
}

.contact-box.comment-box .form-group textarea {
    font-size: 15px;
    border: 1px solid #e5e5e5;
    height: 150px;
}

.recent-blog-area {
    padding-top: 80px;
}

@media (max-width: 575px) {
    .comments, .blog-contact {
        margin-right: 0;
    }
}

/* ******************************
:: 24.0 CONTACT AREA CSS
****************************** */
.contact-us .social-icon {
    display: inline-block;
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 20px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}

.contact-box .form-group input,
.contact-box .form-group textarea {
    font-size: 14px;
    height: 50px;
    padding: 0 15px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.contact-box .form-group textarea {
    height: 160px;
    padding: 15px;
}

.contact-box .form-group input::-webkit-input-placeholder,
.contact-box .form-group textarea::-webkit-input-placeholder {
    color: rgba(68, 68, 68, 0.6);
}

.contact-box .form-group input:-ms-input-placeholder,
.contact-box .form-group textarea:-ms-input-placeholder {
    color: rgba(68, 68, 68, 0.6);
}

.contact-box .form-group input::placeholder,
.contact-box .form-group textarea::placeholder {
    color: rgba(68, 68, 68, 0.6);
}

.contact-box button {
    height: 60px;
    border-radius: 8px;
}

/*Map Area*/
.map-area {
    height: 550px;
    width: 100%;
}

.map-area iframe {
    height: 100%;
    width: 100%;
}

@media (max-width: 575px) {
    .map-area {
        height: 450px;
    }
}

.contact-top > h3 {
    font-size: 2em;
}
.homepage-5 .contact-top h5 {
    line-height: 1.4;
}

.contact-bottom > span {
    font-size: 13px;
}
.contact-bottom > span a {
    text-decoration: underline;
    color: #222;
}

/* ******************************
:: 25.0 FOOTER AREA CSS
****************************** */
.download-area.bg-overlay::after,
.footer-area.bg-overlay::after {
    opacity: 1 !important;
}

.footer-area,
.footer-area a,
.footer-area h3,
.footer-area p,
.footer-area span {
    color: rgba(255,255,255,0.8) !important;
}

.footer-area .social-icons > a {
    width: 40px;
    height: 40px;
    font-size: 18px;
    color: #fff !important;
}

.footer-area .social-icons > a:hover svg:first-child {
    margin-top: -40px;
}

.copyright-area {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-area a:hover {
    color: rgba(255,255,255,1) !important;
}

/* ******************************
:: 26.0 ERROR AREA CSS
****************************** */
.error-area h1 {
    font-size: 18vmax;
}

@media (max-width: 767px) {
    .error-area h1 {
        font-size: 8em !important;
    }
}

@media (max-width: 575px) {
    .error-area h1 {
        font-size: 7em !important;
    }
}

/* ******************************
:: 27.0 COMING SOON AREA CSS
****************************** */
.countdown {
    text-transform: uppercase;
    color: #fff;
}

.countdown > div {
    display: inline-block;
}

.countdown > div > span {
    display: block;
    text-align: center;
}

.countdown-container {
    margin: 0 10px;
    padding: 1rem;
}

.countdown-content span {
    font-size: 1.5em;
}

.countdown-container .countdown-heading {
    font-size: 16px !important;
    font-weight: 600;
    margin: 10px;
}

.countdown-container .countdown-value {
    font-size: 50px;
    font-weight: 600;
    padding: 10px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4)
}

@media (max-width: 767px) {
    .countdown-container {
        margin: 0 6px;
        padding: 0.5rem;
    }
}

@media (max-width: 575px) {
    .countdown-container {
        margin: 0 2px;
        padding: 0;
    }
    .countdown-container .countdown-value {
        font-size: 40px;
    }
}

/* ******************************
:: 28.0 RTL AREA CSS
****************************** */
.rtl .single-work::after {
    content: '\f053';
}

.rtl .single-work:hover::after {
    -webkit-transform: translateX(-5px) translateY(-50%);
    transform: translateX(-5px) translateY(-50%);
}


/* ******************************
:: 29.0 DARK AREA CSS
****************************** */
.dark-bg,
.dark .navbar-sticky-moved-up {
    background-color: #10102d;
}

.dark,
.dark h2,
.dark h3,
.dark p,
.dark span,
.dark a.btn,
.dark .text-body > a,
.dark .plan-features > li,
.dark .team-post,
.dark .team-media a,
.dark .footer-items ul li a,
.dark .copyright-right a {
    color: #fff !important;
}

.dark .owl-carousel button.owl-dot {
    background-color: rgba(255, 255, 255, 0.5);
}

.dark .overlay-dark::after,
.dark .single-review .reviewer::after {
    background-color: #10102d;
}

.dark .navbar-sticky-on {
    -webkit-box-shadow: rgba(255, 255, 255, 0.15) 0px 0px 20px;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 0px 20px;
}

.dark .navbar-dark.navbar-sticky-on .navbar-nav .nav-link:hover,
.dark .navbar-dark.navbar-sticky-on .navbar-nav .nav-link:focus,
.dark .navbar-dark.navbar-sticky-on .navbar-nav .nav-link.active,
.dark .navbar-dark.navbar-sticky-on .navbar-nav .nav-link.current-menu-item {
    color: inherit;
}

.dark .list-box > span::before {
    border-color: #fff;
}

.dark .single-promo:hover,
.dark .single-review .card-top,
.dark .single-review .reviewer::after,
.dark .single-price-plan,
.dark .single-team {
    -webkit-box-shadow: 0 0.313rem 0.875rem rgba(255, 255, 255, 0.15);
    box-shadow: 0 0.313rem 0.875rem rgba(255, 255, 255, 0.15);
}

/* ******************************
:: 30.0 PREVIEW AREA CSS
****************************** */
.preview .welcome-area {
    background-attachment: fixed;
}

.preview .welcome-intro h2 {
    line-height: 1.2;
}

.single-preview > a > img {
    box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.09);
    border-radius: 8px;
    transition: all 0.3s ease 0s;
}

.single-preview h3 {
    font-size: 16px;
    color: #555;
    transition: all 0.3s ease 0s;
}

.single-preview:hover > a > img {
    transform: translateY(-10px);
    box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.12);
}

.preview .copyright-area,
.preview .footer-area a {
    color: #222 !important;
}

@media (min-width: 992px) {
    .preview .welcome-area {
        height: 550px;
    }

    .preview .welcome-intro p {
        font-size: 15px;
        line-height: 28px;
    }

    .slider-height {
        min-height: 700px;
    }
}

@media (max-width: 767px) {
    .preview .welcome-area {
        height: 500px !important;
        padding-top: 0;
    }

    .slider-height {
        min-height: 1450px;
    }
}